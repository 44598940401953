import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Form from '../components/Form'

export const ContactPageTemplate = ({ title, backgroundPosition, content, image, contentComponent }) => {
  const PageContent = contentComponent || Content
  const BackgroundContent = image ? <div
  className="banner-image margin-top-0"
  style={{
    backgroundImage: `url(${
      !!image.childImageSharp ? image.childImageSharp.fluid.src : image
    })`,
    maxWidth: '1000px',
    backgroundPosition: backgroundPosition ? backgroundPosition : `center`,
  }}
/> : <div />
  return (
    <section className="section section--gradient">
      {BackgroundContent}
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <p>If you would like to get notified of changes to our itinerary, please fill out the form below.</p>
              <Form />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  backgroundPosition: PropTypes.string,
  image: PropTypes.object,
  contentComponent: PropTypes.func,
}

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        backgroundPosition={post.frontmatter.backgroundPosition}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        content={post.html}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundPosition
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
