import React from 'react'
import { navigate } from 'gatsby'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Form extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'name': this.state.name,
        'phone': this.state.phone,
      }),
    })
    .then(() => {
      this.setState({ 'success': true, 'error': false })
      setTimeout(() => {
        this.setState({ 'success': false, 'error': false })
      }, 5000)
    })
    .catch(error => {
      this.setState({ 'error': true, 'success': false })
      setTimeout(() => {
        this.setState({ 'success': false, 'error': false })
      }, 5000)
    });
  }

  render() {
    return (<div className="contact-form">
      <form
        name="contact"
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {this.state.success ? <div style={{ textAlign: 'center', color: '#00d900'}}>Submission Successful!<br /></div> : <div />}
        {this.state.error ? <div style={{ textAlign: 'center', color: '#d90000'}}> Failed form submission. Please try again later.<br /></div> : <div />}
        {!this.state.error && !this.state.success ? <br /> : <div />}
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="field">
          <label className="form-label" htmlFor={'name'}>
            Your name
          </label>
          <div className="control">
            <input
              className="input"
              type={'text'}
              name={'name'}
              onChange={this.handleChange}
              id={'name'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="form-label" htmlFor={'phone'}>
            Phone
          </label>
          <div className="control">
            <input
              className="input"
              type={'tel'}
              name={'phone'}
              onChange={this.handleChange}
              id={'phone'}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <button disabled={this.state.success || this.state.error} className="button is-link" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>)
  }
}